*{
    user-select: none;
}
div{
    margin: 0;
    padding: 0;
    border: 0;
}
.tournament-margin {
    display: flex;
    flex-direction: column;
    margin-left: 94px;
    margin-right: 1rem;
    top: 0px;
    position: relative;
    display: flex;
    margin-top: 0.5rem;
}
@media(min-width: 1100.1px){
    .tournament-margin{
        margin-left: 244px;
    }
}
.tournament-fixings{
    background-color: #242226;
    border-radius: 1rem;
    overflow-y: hidden;
    border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.tournament {
    height: 19px;
    flex-shrink: 0;
    color: var(--Catalog-Grey, #707070);
    font-size: 17px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.85px;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}
.tournament {
    padding-top: 0.5rem;
    min-height: 100vh;
    width: inherit;
    position: relative;
}
.type-nav {
    background-color: transparent;
    color: white;
    position: sticky;
    top: 0;
    z-index: 1000;
    height: 41px;
    width: 100%;
}
.nav-layout {
    position: relative;
}
.tab-list {
    margin: 0;
    padding: 0;
    display: flex;
}

ol, ul, menu {
    list-style: none;
    margin: 0;
    padding: 0;
}
.tabs-list-item--active {
    border-color: rgb(172 255 195);
    border-bottom-width: 4px !important;
    padding-bottom: 4px;
}

.tab-item {
    align-items: center;
    display: flex;
    flex-basis: 100%;
    flex-grow: 1;
    height: 41px;
    justify-content: center;
    list-style: none;
    position: relative;
    text-align: center;
    transition: all .25s ease-in-out;
}
.tab-item:not(.tabs-list-item--active) {
    padding-bottom: 4px;
}
.tab-item a {
    color: #F2F4F5;
    height: 100%;
    display: flex;
    font-size: 0.875rem;
    line-height: 1.5rem;
    font-weight: 600;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', sans-serif;
    width: 100%;
    align-items: center;
    justify-content: center;
}
.page-layout {
    margin: 0 auto;
    padding: 10px;
    max-width: 660px !important;
}
.page-layout section {
    display: flex;
    flex-direction: column;
    overflow: visible;
    padding: 25px 18px;
}
.flex {
    display: flex;
}
.listCard-title {
    color: #F2F4F5;
    font-size: 14px;
    font-weight: 600;
    letter-spacing: 1.1px;
    margin-bottom: 12px;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', sans-serif;
}
.listCard-title-sm {
    color: rgb(199, 199, 201) !important;
    font-size: 10px;
    font-weight: 500;
    margin-bottom: 12px;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', sans-serif;
    margin-left: 1rem;
    padding-top: 0.25rem;
}
.scroller {
    width: 100%;
    overflow-x: auto;
    overflow-y: hidden;
    position: relative;
    vertical-align: top;
    white-space: nowrap;
}
.responsive-table table {
    table-layout: fixed;
    width: 100%;
}

table {
    text-indent: 0;
    border-color: inherit;
    border-collapse: collapse;
}
.table-head {
    display: table-header-group;
    scroll-behavior: smooth;
}
.table-tr {
    display: table-row;
}
.table-th {
    text-align: left;
    color: #F2F4F5;
    border-color: rgb(220, 221, 223);
    border-bottom: 1px solid #121212;
    border-top: 1px solid #121212;
    font-size: 11px;
    height: 24px;
    line-height: 15px;
    text-transform: uppercase;
    display: table-cell;
    font-variant-numeric: tabular-nums;
    padding: 4px 4px 3px;
    vertical-align: middle;
    white-space: nowrap;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', sans-serif;
}
.page-layout section{
    display: flex;
    flex-direction: column;
    overflow: visible;
    padding: 25px 18px;
}
.page-layout header{
    display: flex;
    margin-bottom: 1.25rem;
}
.page-layout header > *{
   flex: 1 1 0%;
   font-size: 22px;
   color: #F2F4F5;
   margin-bottom: 0.5rem;
   font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', sans-serif;
}
.listCard-title{
    color: #F2F4F5;
    font-size: 14px;
    font-weight: 600;
    letter-spacing: 1.1px;
    margin-bottom: 12px;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', sans-serif;
}
.listCard-title-sm{
  color: rgb(199, 199, 201) !important;
  font-size: 10px;
  font-weight: 500;
  margin-bottom: 12px;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', sans-serif;
  margin-left: 1rem;
  padding-top: 0.25rem;

}
.flex{
    display: flex;
}
.fg-0{
  flex-grow: 0 !important;
}
.scroller{
    width: 100%;
    overflow-x: auto;
    overflow-y: hidden;
    position: relative;
    vertical-align: top;
    white-space: nowrap;
}
.responsive-table table{
    table-layout: fixed;
    width: 100%;
}
.table-head{
    display: table-header-group;
    scroll-behavior: smooth;
}
.table-tr{
    display: table-row;
}
.page-layout{
    margin: 0 auto;
    padding: 10px;
    max-width: 660px !important;
}
.table-td{
  color: #F2F4F5;
  font-size: 0.75rem;
  line-height: 1.5rem;
  font-weight: 600;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', sans-serif;
  display: table-cell;
  font-variant-numeric: tabular-nums;
  padding: 4px 4px 3px;
  vertical-align: middle;
  white-space: nowrap;
}
.group-join button{
  width: 68px;
  background-color: #0866FF;
  border: 2px solid rgba(0, 102, 204, .48);
  color: #f2f4f5;
  height: 28px;
  padding: 0 12px;
  border-radius: .4rem;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', sans-serif;
  font-size: 0.75rem;
  line-height: 1.5rem;
  font-weight: 600;
  cursor: pointer;
}
.grouplist-card{
  width: 80px;
  text-align: center;
}
.nowrap{
    white-space: nowrap;
}
.email-push{
    cursor: pointer;
    margin-left: 1rem;
}