/* Full-screen grey overlay */
.loading-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7); /* Opaque grey */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 90000;
  }
  
  /* Centered content with the loading circle */
  .loading-content {
    text-align: center;
    color: white;
    font-family: Arial, sans-serif;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  /* Loading circle animation */
  .loading-circle {
    border: 5px solid rgba(255, 255, 255, 0.3); /* Light grey border */
    border-top: 5px solid white; /* White border for the top */
    border-radius: 50%;
    width: 50px;
    height: 50px;
    animation: spin 1s linear infinite;
    margin-bottom: 10px;
  }
  
  /* Keyframes for spinning */
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  
  /* Message styling */
  .loading-content p {
    margin: 0;
    font-size: 1.2rem;
  }
  