.board-standing-container{
    margin-left: 280px;
    margin-top: calc(56px + 1rem);
}
.d-flex{
    display: flex;
}
.score-background{
    background: #80808017;
    width: 370px;
    border-top-right-radius: 0.5rem;
    border-bottom-right-radius: 0.5rem;
}
.spc-btwn{
    justify-content: space-between;
}
.mt-3{
    margin-top: 3rem;
}
.standings{
   padding-left: 1rem;
}
.standings-username{
    min-width: 91px;
    justify-content: center;
    display: flex;
}
.standings-section{
    width: 100%;
}
.standings-color{
    color: hsla(0,0%,100%,.72);
}
.standings-points{
    color: #fff;
    font-size: 1.5rem;
}
.start-next{
    color: #fff;
    justify-content: center;
    display: flex;
    margin: 1rem 0;
    font-size: 0.875rem;
}
.lobby-leave{
    margin-bottom: 1rem;
    cursor: pointer;
}
.column-reverse{
    flex-direction: column-reverse;
}
.outer-chessboard{
    padding: 2rem;
    background: #F0E4D7;
}
.chessboard-edge{
    border: 3px #4A6E4D solid
}
.ig-profile .ingame-username{
    min-width: 90px;
    margin-left: 1rem;
    overflow: hidden;
    text-overflow: ellipsis;
}
.ingame-username{
    display: inline !important;
    color: #fff;
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
    cursor: pointer;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif;
}
.move-body{
    margin: 1rem;
}
.f-12{
    font-size: 12px;
}
.move-bck{
    background: rgba(169, 169, 169, 0.1);
}
.left-curve{
    border-top-left-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem;
}
.right-curve{
    border-top-right-radius: 0.25rem;
    border-bottom-right-radius: 0.25rem;
}
.pl-left1{
    padding-left: 1rem;
}
.history-scroll{
    max-height: 230px;
    overflow-y: auto;
    display: block;
}
.history-scroll tr{
    width: 100%;
    display: table;
    width: 100%;
    table-layout: fixed;
}
.t-0{
    top: 0 !important;
}
.piece-basket{
    display: flex;
    margin-left: 1rem;
}
.add-space{
    display: flex;
    flex: 1;
}
.add-svg{
    margin-left: auto;
    background: rgb(172 255 195);
    border-radius: 4px;
    padding: 0 0.5rem;
    font-weight: 700;
}