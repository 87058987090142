.modal.samri-siyed{
    position: fixed;
    height: 825.6px;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    z-index: 3500;
    padding: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: .875rem;
}
.overlay{
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    background: #000000bf;
    touch-action: none;
}
.card.samri-siyed{
    border-radius: .5rem;
    background-position: center;
    position: relative;
    width: 100%;
    min-width: 200px;
    max-width: 500px;
    max-height: calc(100% - 4em);
    display: flex;
    flex-direction: column;
    background: #1a2c38;
    color:  #b1bad3;
    overflow: hidden;
}
.card .header{
     padding: 1rem;
     justify-content: space-between;
     flex-direction: row;
}
.header .x-button{
    background: transparent;
    border: none;
    color: rgba(177, 186, 211, 1);
}
.card .header div{
    color: #ffffff;
    line-height: 1.5;
    text-align: left;
    justify-content: flex-start;
    font-size: 1rem;
    font-weight: 600;
    display: inline-flex;
    align-items: center;
}
.card .header div .svg-icon{
    margin-right: 0.5rem;
    color: #b1bad3;
}
.content.withdraw.scroll{
    background: white;
    padding: 1rem;
}
.scroll{
    overscroll-behavior: contain;
    scrollbar-color: #2f4553 transparent;
}
.modal-content{
    flex: 1;
    display: flex;
    flex-direction: column;
    position: relative;
}
.content-padding{
    padding-right: 1rem;
    padding-left: 1rem;
    display: flex;
    flex-direction: column;
    padding-bottom: 1rem;
    gap: 1rem;
}
.content-padding .container{
    display: flex;
    flex-direction: column;
    flex: 1;
    gap: 1rem;
    min-height: 25rem;
}
.modal-space{
    justify-content: space-between;
    flex-direction: column;
    flex: 1 1 0%;
    display: flex;
}
.gap{
    gap: 1rem;
}
.column{
    flex-direction: column;
}
.currency{
    background-color:#213743;
    padding: 1rem;
    border-radius: 0.5rem;
}
.currency-header{
    display: flex;
    justify-content: space-between;
    padding-bottom: 0.5rem;
    border-bottom: 1px solid #557086;
    font-size: 0.875rem;
    font-weight: 600;
}
.doubloon{
    position: relative;
    width: 100%;
    padding-top: 1rem;
}
.doubloon-container{
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    gap: 0.5rem;
    width: 100%;
}
.doubloon-details{
    display: inline-flex;
    align-items: center;
    gap: 0.5rem;
}
.currency-container{
    display: flex;
    flex-direction: column;
    gap: 0.125rem;
}
.sticky{
    display: flex;
    align-items: flex-end;
    position: sticky;
    bottom: 0;
    width: 100%;
    background-color: #1a2c38;
    padding: 1rem 0;
    margin-bottom: calc(-1* 1rem);
}
.flow-currency-btns{
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 0.5rem;
    width: 100%;
}
.currency-buttons{
    color: rgba(255, 255, 255, 1);
    line-height: 1;
    font-weight: 600;
    padding: 0.9375rem 1.25rem;
    background-color: rgba(20, 117, 225, 1);
    border-radius: 0.25rem;
    white-space: nowrap;
    gap: 0.5rem;
    justify-content: center;
    align-items: center;
    width: 100%;
    display: inline-flex;
    position: relative;
}
.currency-buttons.deposit{
    background-color: rgb(58, 188, 53);
}
.marker{
    color: #ffffff;
    font-size: 0.875rem;
    font-weight: 600;
}
.marker-subscript{
    color: #b1bad3;
    font-size: .75rem;
    font-weight: 400;
}
.value-container span{
    color: #ffffff;
    font-size: 0.875rem;
    font-weight: 600;
}
.ab{
    margin-bottom: 1rem;
}
.order{
    width: 100%;
    flex-direction: row;
    background-color: transparent;
    display: flex;
}
.line{
    background-color: rgb(196, 196, 196) !important;
    height: 1px !important;
    flex-grow: 1;
    align-self: center;
    display: flex;
}
.line-title{
    line-height: 2rem;
    font-weight: 700 !important;
    color: #ffffff;
    padding: 0 1rem;
    font-size: 1.25rem;
    text-transform: uppercase;

}
.margin{
    margin: 0 auto;
    max-width: 325px;
}
.payment-selection{
    grid-template-columns: repeat(1, 1fr);
    grid-auto-rows: 1fr;
    gap: 0.5rem;
    display: grid;
    margin-bottom: 2rem;
}
.payment-item{
    min-height: 121px;
    min-width: 138.5px;
    border: 1px solid rgb(207, 214, 219);
    position: relative;
    background-color: #ffffff;
    border-radius: 0.25rem;
    flex-direction: column;
    display: flex;
}
.payment-item .padding{
    padding: 1.25rem 0.25rem;
    color: rgb(20, 147, 255);
    cursor: pointer;
    justify-content: center;
    align-items: center;
    flex: 1 1 0%;
    flex-direction: column;
    display: flex;
}
.issuing-img{
    align-content: center;
    justify-content: space-evenly;
    flex-wrap: wrap;
    flex-direction: row;
    max-width: 100%;
    display: flex;
}
.img-spc{
    justify-content: center;
    row-gap: 0.25rem;
    flex-wrap: wrap;
    font-size: 0.75rem;
    display: flex;
}
.previous{
    color: rgba(177, 186, 211, 1);
    font-weight: 600;
    line-height: 1;
    font-size: 0.875rem;
    padding: 0.5rem;
    border-radius: 0.25rem;
    white-space: nowrap;
    gap: 0.5rem;
    justify-content: center;
    align-items: center;
    display: inline-flex;
    position: relative;
    cursor: pointer;
    background: transparent;
}
.white{
    color: #ffffff;
}
  .button--small {
    padding: 10px 20px;
    font-size: 0.875rem;
  }
  .button--green {
    outline: none;
    background-color: #64d18a;
    border-color: #64d18a;
    color: white;
    transition: all 200ms ease;
  }
  
  .button--green:hover {
    background-color: #8bdda8;
    color: white;
  }
  .check-icon::after {
    content: '';
    position: absolute;
    top: 50%;
    left: 46%;
    transform: scaleX(-1) rotate(135deg);
    height: 33px;
    width: 15px;
    border-top: 4px solid #5cb85c;
    border-right: 4px solid #5cb85c;
    transform-origin: left top;
    animation: check-icon 0.8s ease;
}
  @keyframes check-icon {
    0%{
        height: 0;
        width: 0;
        opacity: 1;
    }
    20%{
        height: 0;
        width: 10px;
        opacity: 1;
    }
    40%{
        height: 33px;
        width: 15px;
        opacity: 1;
    }
    100%{
        height: 33px;
        width: 15px;
        opacity: 1;
    }
  }
  .checked-a{
    animation: none !important;
  }
  .checked{
    animation: none !important;
    stroke: #5cb85c !important;
    transition: stroke 0.5s ease-out !important;
  }
  .form-field{
    height: 60px;
  }
  .balance-info{
    justify-content: center;
    display: flex;
    margin-bottom: 1rem;
  }
  .amount-section{
    border: 1px rgb(207, 214, 219) solid;
    border-radius: 0.25rem;
    padding: 1rem;
  }
  .amount-input{
    display: flex;
  }
  .card-info{
    border-top: 1px rgba(185,196,203,0.4)solid;
    border-bottom: 1px rgba(185,196,203,0.4) solid;
    margin: 1rem 0;
    padding: 1rem;
  }
  .actions{
    justify-content: center;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
  }

  .submit-withdraw-container{
    justify-content: center;
    display: flex;
  }

  .withdraw-button{
    padding: 1rem;
    background: rgb(58, 188, 53);
    color: white;
    border-radius: 4px;
    width: 100%;
  }

  .light-weight{
    font-weight: 400;
  }
  .reg-font{
    font-size: 1rem;
  }