@keyframes slideIn {
    from {
        left: -300px;
        opacity: 0;
    }
    to {
        left: 1rem;
        opacity: 1;
    }
}
@keyframes timebar {
    from {
        width: 100%;
    }
    to {
        width: 0;
    }
}

.notification-msg-placement {
    position: fixed;
    top: calc(56px + 1rem);
    left: 1rem;
    background: white;
    z-index: 9999;
    border-radius: 0.5rem;
    padding: 1rem;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    animation: slideIn 0.5s ease-out;
}

.notification-title {
    color: rgb(172 255 195);
    font-weight: 700;
    text-transform: uppercase;
}

.notification-msg {
    font-size: 12px;
}
.timebar {
    position: absolute;
    bottom: 0;
    left: 0;
    height: 4px; 
    background-color: rgb(172 255 195);
    animation: timebar 3s linear forwards;
}