.footer-container{
    color: hsla(0,0%,100%,.5);
}
.footer-padding{
    padding: 1.25rem 2rem;
}
.spc-btwn{
    justify-content: space-between;
}
.flex{
    display: flex;
}
.fg-0{
    flex-grow: 0;
}
.mr-4{
    margin-right: 1rem;
}
.footer-platforms-nav{
    gap: 1rem;
    display: flex;
}
.of-padding{
    padding: 3.5rem 2rem 1.5rem 2rem ;
}
.agepluslogo{
    background-image: url('../assets/images/18+.png');
    height: 3.5rem;
    margin-left: 1rem;
    margin-bottom: 0.5rem;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
}
.of-message{
    color: hsla(0, 0%, 100%, .5);
    font-size: 10px;
    display: flex;
    margin-top: 3rem;
    gap: 1rem;
}
.ta-c{
    text-align: center;
}
.p-04{
    padding: 0 1rem;
}
.ghchess-info{
    color: #ffffff80;
    font-size: 10px;
}
.info-row{
    display: flex;
    justify-content: space-around;
}
.chess-rewards-title{
    font-size: 16px;
    font-weight: 600;
    color: rgb(203 203 203);
    margin-bottom: 1rem;
}

.info-row > section {
    flex: 1;
    padding: 20px;
    max-width: 400px;
}