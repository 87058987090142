.standard{
    align-items: stretch;
    background-color: rgba(0, 0, 0, 0.00);
    border: 0 solid black;
    box-sizing: border-box;
    display: flex;
    flex-basis: auto;
    flex-direction: column;
    flex-shrink: 0;
    list-style: none;
    margin: 0px;
    min-height: 0px;
    min-width: 0px;
    padding: 0px;
    position: relative;
    text-decoration: none;
    z-index: 0;
}
.p-m{
    padding-left: 0.5rem;
    padding-right: 0.5rem;
}
.border{
    border-bottom-color: #1e1e1e;
    border-style: solid;
    border-bottom-width: 0.0625rem;
    border-width: 0;
}
.gap-s{
    gap: 0.25rem;
}
.w-full{
    width: 100%;
}
.flex{
    display: flex;
}
.border-box{
    box-sizing: border-box;
}
.h-275{
    min-height: 2.75rem;
    height: 2.75rem;
}
.pl-m{
    padding-left: 0.5rem;
}
.mr-auto{
    margin-right: auto;
}
.mw-100-40{
    max-width: calc(100% - 40px);
}
.semibold{
    font-weight: 600;
}
.text-1{
    line-height: 1.25rem;
    font-size: 0.875rem;
}
.font-sans{
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", sans-serif;
}
.o-h{
    overflow: hidden;
}
.fw-nw{
    flex-wrap: nowrap;
}
.button.icon{
    width: 2rem;
}
.button{
    color: #acacac;
}
.p-rem6{
    padding-left: 0.375rem;
    padding-right: 0.375rem;
}
.jc-c{
    justify-content: center;
}
.d-if{
    display: inline-flex;
}
.c-w{
    color: #ffffff;
}
.bottom-buttons{
    align-items: end;
    flex-direction: row-reverse;
    display: flex;
    gap: 1rem;
    padding: 0.75rem 1rem;
    background-color: #121212;
}
.p-rem10{
    padding-left: .625rem;
    padding-right: .625rem;
}
main{
    align-self: center;
    box-sizing: border-box;
    gap: 0.5rem;
    max-width: 618px;
    overflow-y: auto;
    overscroll-behavior-y: none;
    padding: 0.75rem;
}
.users-multiselect{
    display: flex;
    flex-direction: column;
    font: normal 400 1rem / 1.25rem -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", sans-serif;
    gap: 0.5rem;
    height: 100%;
    outline: none;
    position: relative;
}
form, main{
    display: flex;
    flex-direction: column;
    width: 100%;
}
form{
    overflow: hidden;
}
.create-chat{
    align-items: center;
    display: flex;
    flex-direction: column;
    overflow: hidden;
}
label{
    display: flex;
    position: relative;
}
.interior-label{
    height: 100%;
    width: 100%;
}
.p-l{
    padding-left: 1rem;
    padding-right: 1rem;
}
.label-container{
    display: inline-flex;
    position: relative;
    align-items: center;
    background-color: #1e1e1e;
    border-radius: 1rem;
    box-sizing: border-box;
    min-height: 3rem;
    border: 1px solid rgb(54, 54, 54);
}
.label-container , .input-boundary-box{
    flex: 1;
}
.label-container:not(.without-label) .input-boundary-box {
    padding: .5rem 0;
}
.input-boundary-box{
    display: flex;
    align-items: center;
}
.label-container:not(.without-label) .input-container.stateful-input{
    transform: translateY(0.5rem);
}
.input-container{
    position: relative;
    display: flex;
    align-items: center;
    width: 100%;
    line-height: 1.25rem;
    font: normal 400 1rem / 1.25rem -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", sans-serif;
}
.activated, .activated .label-text.inner-label{
    transition: all 150ms ease-out;
}
.activated, .activated .label-text.inner-label{
    transition: all 150ms ease-out;
}
.stateful-input .label-text.inner-label{
    transform: translate(calc(-1 * 0px), calc(-1 * 1rem));
    font-size: 0.75rem;
    line-height: 1rem;
}
.label-text{
    display: inline;
    position: absolute;
    top: 1.125rem;
    pointer-events: none;
}
.label-text, .input-suffix, .input-prefix {
    color: #82959B;
}
.input-bar{
    column-gap: 0.25rem;
    display: flex;
    flex-wrap: wrap;
    padding-top: 1.5rem;
    padding-bottom: 0.5rem;
    row-gap: 0.5rem;
    width: 100%;
}
.input-bar>input{
    background: transparent;
    border: none;
    flex: 1;
    outline: none;
    font: inherit;
    color: #F2F4F5;
    padding: 0;
}
.label-container:focus-within {
    border-color: #ffffff;
    border-style: solid;
    border-width: 2px;
}

.list-n{
    list-style-type: none;
}
.mt-0{
    margin-top: 0;
}
.relative{
    position: relative;
}
.j-btwn{
    justify-content: space-between;
}
.gap-m{
    gap: 0.5rem;
}
.px-rem16{
    padding-left: 1rem;
    padding-right: 1rem;
}
.py-rem8{
    padding-bottom: 0.5rem;
    padding-top: 0.5rem;
}
.outline-offset-1{
    outline-offset: -1px;
}
.pointer{
    cursor: pointer;
}
.shrink{
    flex-shrink: 1;
}
.shrink-0{
    flex-shrink: 0;
}
.lh-rem16{
    line-height: 1rem;
}
.hw-xl{
    height: 2rem;
    width: 2rem;
}
.text-rem20{
    font-size: 1.25rem;
}
.rounded{
    border-radius: 9999px;
}
.hw-rem24{
    width: 1.5rem;
}
.d-ib{
    display: inline-block;
}
.flex-col{
    flex-direction: column;
}
.py-rem6{
    padding-bottom: 0.375;
    padding-top: 0.375;
}
.text-l{
    line-height: 1.25rem;
    font-size: 0.875rem;
}
.search-result{
    border-radius: 0.5rem;
    background-color: #0B1416;
}
.h-rem24{
    height: 1.5rem;
}
.h-full{
    height: 100%;
}
.pointer-n{
    pointer-events: none;
}
.invalid-content{
    color: #FF6E80;
}
.ml-rem16{
    margin-left: 1rem;
}
.text-neutral{
    color: #82959B;
}
.hw-rem16{
    height: 1rem;
    width: 1rem;
}
.item-bar-item{
    align-items: center;
    background-color: #303030;
    display: flex;
    border-radius: 1rem;
    font: normal 400 .75rem / 1rem -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", sans-serif;
    font-weight: 600;
    gap: 0.5rem;
    padding: 0.5rem 0.75rem 0.5rem 0.5rem;
    color: #ffffff;
}
.item-bar-item:focus {
    outline: none;
    border-color: #ffffff;
    border-style: solid;
    border-width: 2px;
}
.bottom-buttons button:not(:disabled):hover {
    color: #ffffff;
}
.button.hover{
    background-color: transparent;
    border: 0 solid transparent;
    border-radius: 9999px;
    box-sizing: border-box;
    cursor: pointer;
    font: normal 600 .75rem / 1rem -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", sans-serif;
    height: 2rem;
    overflow: hidden;
    text-align: center;
    text-overflow: ellipsis;
    white-space: nowrap;
}
.button:disabled{
    color: #acacac;
    cursor: not-allowed;
    pointer-events: none;
    background-color: #ffffff0d !important;
}
.button-ready{
    background-color: rgb(0, 146, 249) !important;
    color: #ffffff;
}
.banner , .timeline{
    padding: 0.5rem 0.75rem;
}
.timeline{
    flex-direction: column;
    transform: translate3d(0px, 0px, 0px);
    max-width: 600px;
    justify-content: flex-start;
    flex-grow: 1;
    width: 100%;
}
.banner{
    border-radius: 0.5rem;
    font: normal 400 .75rem / 1rem -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", sans-serif;
    font-weight: 600;
    background-color: rgba(98, 159, 255, 0.2);
    color: #ffffff;
}
.message-form{
    background-color: #121212;
    position: fixed;
    bottom: 10px;
    width: 410px;
}
.message-instruments{
    align-items: center;
    display: flex;
    flex-direction: row;
    margin: 4px 12px;
    padding: 4px;
    border-radius: 1rem;
    border-top-color: #1e1e1e;
    border-top-width: 1px;
    border-top-style: solid;
    background-color: #1A282D;
}
.zi-1{
    z-index: 1;
}
.h-rem02{
    height: 0.2rem;
}
.us-n{
    user-select: none;
}
.progress{
    transition-timing-function: linear;
    transition-property: width;
    transition-duration: 0.5s;
}
.zi--1{
    z-index: -1;
}
.my-rem12{
    margin-left: 12px;
    margin-right: 12px;
}
.mx-rem4{
    margin-bottom: 0.25rem;
    margin-top: 0.25rem;
}
.p-rem4{
    padding: 0.25rem;
}
.br-rem16{
    border-radius: 16px;
}
.image-buttons{
    margin-right: 0.25rem;
    flex-direction: row;
    display: flex;
    justify-content: center;
}
.img{
    transition-duration: 0.2s;
    user-select: none;
    transition-property: background-color, box-shadow;
    min-height: 2.25rem;
    min-width: 2.25rem;
    cursor: pointer;
    border-radius: 9999px;
    border-width: 1px;
    border-style: solid;
    display: flex;
}
.img-child{
    line-height: 1.25rem;
    word-wrap: break-word;
    text-align: center;
    min-width: 0px;
    font-weight: 700;
    font-size: 15px;
    align-items: center;
    flex-direction: row;
    justify-content: center;
    flex-grow: 1;
    display: flex;
}
.file-input{
    width: 0.1px;
    height: 0.1px;
    position: absolute;
    opacity: 0;
    z-index: -1;
    overflow: hidden;
}
input[type="file" i]{
    appearance: none;
    background-color: initial;
    cursor: default;
    color: inherit;
    text-overflow: ellipsis;
    text-align: start !important;
    padding: initial;
    border: initial;
    white-space: pre;
}
.op-05{
    opacity: 0.5;
}
.fill{
    fill: currentColor;
}
.mw-full{
    max-width: 100%;
}
.w-rem20{
    width: 1.25rem;
}
.va-tb{
    vertical-align: text-bottom;
}
svg:not(:root){
    overflow: hidden;
    overflow-clip-margin: content-box;
}
.text-area{
    padding: 0;
    background-color: transparent;
    resize: none;
    border: none;
    appearance: none;
    box-sizing: border-box;
    font-size: 1rem;
    line-height: 1.375rem;
    width: 100%;
    min-height: 2.5rem;
    color: hsl( 210 calc( 1 * 9.1%) 87.1% / 1);
    position: relative;
}
.text-placeholder{
    padding-top: 0.6rem;
    padding-bottom: 0.5rem;
    padding-right: 10px;
    left: 0;
    right: 10px;
    position: absolute;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    color: hsl( 229 calc( 1 * 4.8%) 44.9% / 1);
    user-select: none;
    pointer-events: none;
    font: normal 400 .875rem / 1.25rem -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", sans-serif;
}
.textbox{
    padding-bottom: 0.5rem;
    padding-top: 0.6rem;
    padding-right: 10px;
    left: 0;
    right: 10px;
    caret-color: hsl( 210 calc( 1 * 9.1%) 87.1% / 1);
    text-align: left;
    word-break: break-word;
    user-select: text;
    color: hsl( 210 calc( 1 * 9.1%) 87.1% / 1);
    font-size: 1rem;
    line-height: 1.375rem;
    font: normal 400 .875rem / 1.25rem -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", sans-serif;
}
.room{
    display: grid;
    grid-template-rows: min-content 1fr min-content;
    height: 100%;
    overflow: auto;
}
.op-1{
    opacity: 1;
}
.message-container{
    border-radius: 1.5rem;
    border-bottom-right-radius: 0.25rem;
    align-self: flex-end;
    transition-duration: 0.2s;
    background-color: rgb(29, 155, 240);
    max-width: 100%;
    box-sizing: border-box;
    transition-property: background-color, box-shadow;
    outline-style: none;
    padding-left: 1rem;
    padding-right: 1rem;
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
    border-color: rgb(29, 155, 240);
}
.font-div{
    word-break: break-word;
    font-family: TwitterChirp, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif;
    line-height: 1.25rem;
    word-wrap: break-word;
    text-align: left;
    position: relative;
    min-width: 0px;
    font-size: 15px;
    font-weight: 400;
    overflow: hidden;
}
.message{
    word-wrap: break-word;
    font-family: inherit;
    min-width: 0px;
}
.pb-rem24{
    padding-bottom: 1.5rem;
}
.os-n{
    outline-style: none;
}
.ai-fe{
    align-items: flex-end;
}
.jc-fe{
    justify-content: flex-end;
}
.pr-rem4{
    padding-right: 0.25rem;
}
.op-0{
    opacity: 0;
}
.fs-1{
    flex-shrink: 1;
}
.inline{
    display: inline;
}
.pe-ni{
    pointer-events: none !important;
}
.react-container{
    user-select: none;
    transition-duration: 0.2s;
    transition-property: background-color, box-shadow;
    min-height: 2.25rem;
    min-width: 2.25rem;
    outline-style: none;
    border-radius: 9999px;
    border-width: 1px;
    border-style: solid;
    align-items: stretch;
}
.react{
    font-family: TwitterChirp, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif;
    line-height: 1.25rem;
    word-wrap: break-word;
    text-align: center;
    min-width: 0;
    font-weight: 700;
    font-size: 15px;
    align-items: center;
    flex-direction: row;
    justify-content: center;
    flex-grow: 1;
    display: flex;
}
.timestamp{
    width: 87.5%;
    margin-top: .375rem;
    align-self: flex-end;
    justify-content: flex-end;
    overflow: hidden;
}
.transition{
    transition-duration: 0.2s;
    transition-property: background-color, box-shadow;
    outline-style: none;
}
.ai-b{
    align-items: baseline;
}
.time-div{
font-family: TwitterChirp, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif;
font-size: 13px;
word-wrap: break-word;
text-align: right;
line-height: 16px;
font-weight: 400;
}
.p-1{
    padding: 1rem;
}
.time{
    word-wrap: break-word;
    font-family: inherit;
    min-width: 0px;
}
.scrollbar{
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    height: 100%;
    overflow: hidden auto;
    overscroll-behavior-y: none;
    width: 100%;
    max-height: 390px;
}
::-webkit-scrollbar {
    width: 0.875rem;
  }
  ::-webkit-scrollbar-track {
    border: none;
  }
  ::-webkit-scrollbar-thumb {
    background-clip: padding-box;
    background-color: #303030;
    border: 3px solid transparent;
    border-radius: calc(0.875rem / 2);
    min-height: 50px;
  }
  ::-webkit-scrollbar-thumb:hover {
    background-color: #303030;
  }

.standard-v2{
    background-color: rgba(0, 0, 0, 0.00);
    border: 0 solid black;
    box-sizing: border-box;
    color: rgba(0, 0, 0, 1.00);
    display: inline;
    font: 14px -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif;
    list-style: none;
    margin: 0px;
    padding: 0px;
    text-align: inherit;
    text-decoration: none;
    word-wrap: break-word;
}
.standard-v2-inherit{
    background-color: rgba(0, 0, 0, 0.00);
    border: 0 solid black;
    box-sizing: border-box;
    color: inherit;
    display: inline;
    font: inherit;
    list-style: none;
    margin: 0px;
    padding: 0px;
    text-align: inherit;
    text-decoration: none;
    white-space: inherit;
    word-wrap: break-word;
}
.bs-bb{
    box-sizing: border-box;
}
.d-f{
    display: flex;
}
.d-if{
    display: inline-flex;
}
.d-i{
    display: inline;
}
.d-b{
    display: block;
}
.d-g{
    display: grid;
}
.p-r{
    position: relative;
}
.zi-0{
    z-index: 0;
}
.mh-100{
    max-height: 100%;
}
.h-100{
    height: 100%;
}
.w-100{
    width: 100%;
}
.bc-drk-rglr{
    background-color: #242226;
}
.bc-t{
    background: transparent;
}
.o-h{
    overflow: hidden;
}
.oy-v{
    overflow-y: visible;
}
.ox-v{
    overflow-x: visible;
}
.as-c{
    align-self: center;
}
.ac-stretch{
    align-content: stretch;
}
.ai-c{
    align-items: center;
}
.fd-c{
    flex-direction: column;
}
.fd-r{
    flex-direction: row;
}
.fw-400{
    font-weight: 400;
}
.fw-600{
    font-weight: 600;
}
.fw-700{
    font-weight: 700;
}
.lh-18{
    line-height: 18px;
}
.lh-20{
    line-height: 20px;
}
.fs-14{
    font-size: 14px;
}
.fs-16{
    font-size: 16px;
}
.c-w{
    color: #fff;
}
.c-g{
    color: #ababab;
}
.c-b{
    color: rgb(0, 149, 246);
}
.jc-fs{
    justify-content: flex-start;
}
.fg-1{
    flex-grow: 1;
}
.font{
    font-family:  -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif;
}
.cursor-p{
    cursor: pointer;
}
.bord-s{
    border: solid;
}
.right-0{
    right: 0;
}
.w-50{
    width: 50%;
}
.hov-w:hover{
    color: #fff;
}
.trans-color{
    transition: color .2s ease-out;
}
.pd-45{
    padding: 0.45rem;
}
.pd-1{
    padding: 1rem;
}
.ta-c{
    text-align: center;
}
.oy-auto{
    overflow-y: auto;
}
.m-0{
    margin: 0;
}

.chat-shell{
    height: inherit;
}
.shell{
    display: grid;
    grid-template-columns: auto 1fr;
    height: 100vh;
}
.container{
    display: grid;
    grid-template-columns: 220px 1fr;
    height: inherit;
    container-type: size;
}
.r-chat-sb{
    border-right: 1px solid #1e1e1e;
    overflow: auto;
}
.r-chat-header{
    padding: 0.75rem 1rem 0.75rem 1rem;
}
.create-icon{
    border-radius: 9999px;
    padding: 0.44rem;
    margin-right: 0.25rem;
    margin-left: auto;
}
.create-icon:hover{
    background-color: #2b2b30;
}
.border{
    border-bottom-color: #1e1e1e;
    border-style: solid;
    border-width: 0px;
    border-bottom-width: 0.0625rem;
}
.click-tabs{
    padding-top: 1rem;
    padding: 0.45rem;
}
.welcome{
    overflow: auto;
    grid-template-rows: min-content 1fr;
}
.welcome-chat-header{
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    gap: 0.25rem ;
    min-height: 2.75rem;
    height: 2.75rem;
}
.fit{
    padding-left: 0.5rem;
    max-width: calc(100% - 40px);
    margin-right: auto;
}

h4{
    margin-top: 0.75rem;
    margin-bottom: 0.5rem;
}
.conversations{
    flex: 1 1 0%;
    height: 100%;
    overflow: auto;
    overscroll-behavior-y: none;
    width: 100%;
}
.room-container{
    box-sizing: border-box;
    display: grid;
    grid-template-columns: min-content 1fr;
    align-items: center;
    justify-content: left;
    min-height: 60px;
    text-align: left;
    border-width: initial;
    border-style: none;
    border-color: initial;
    border-image: initial;
    padding: 0.75rem;
    grid-column-gap: 0.5rem;
    grid-row-gap: 0.25rem;
    font: normal 400 .75rem / 1rem -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", sans-serif;
    text-decoration: none;
    width: 100%;
}
.avatar{
    box-sizing: border-box;
    grid-row: 1 / span 2;
    height: 2rem;
    width: 2rem;
}
.inline-flex{
    display: inline-flex;
}
.h-rem2{
    height: 2rem;
}
.w-rem2{
    width: 2rem;
}
.border-bx{
    box-sizing: border-box;
}
.row{
    display: flex;
    gap: 0.5rem;
    justify-content: space-between;
    min-width: 0px;
}
.last-msg, .last-msg-time, .subreddit-name {
    color: #ACACAC;
}
.last-msg, .room-name, .subreddit-name {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
.room-name{
    color: #f2f2f2;
}
.last-msg-time{
    font: normal 400 .625rem / 1rem -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", sans-serif;
    white-space: nowrap;
}
.ai-fs{
    align-items: flex-start;
}
.default{
    cursor: default;
}
.fd-rr{
    flex-direction: row-reverse;
}
.pl-rem4{
    padding-left: 0.25rem;
}
.jc-fs{
    justify-content: flex-start;
}
.msg-received{
    border-radius: 1.5rem;
    border-bottom-left-radius: 0.25rem;
    align-self: flex-start;
    transition-duration: 0.2s;
    background-color: rgb(47, 51, 54);
    max-width: 100%;
    box-sizing: border-box;
    transition-property: background-color, box-shadow;
    outline-style: none;
    padding-left: 1rem;
    padding-right: 1rem;
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
    border-color: rgb(51, 54, 57);
}
.timestamp-received{
    width: 87.5%;
    margin-top: 0.375rem;
    overflow: hidden;
}
.cg-container{
    width: 100%;
    max-width: 100%;
    display: block;
}
.unread_alert{
    font-size: 25px;
    color: #0b93f6;
}