.flex{
    display: flex;
}
.geo-item{
    flex: 1; 
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;  
    color: hsla(0,0%,100%,.5);
    padding: 1rem;
    cursor: pointer;
}
.selected{
    color: white;
    border-bottom: 4px solid rgb(172 255 195);
}
.title{
    margin-top: 1rem;
    padding-bottom: 1rem;
    border-bottom: 0.5px solid ;
    color: #fff;
}
.tournament-list{
    max-height: 200px;
    overflow: auto;
}
.tournament-list{
    margin-top: 1rem;
}
.tournament-list .tournament-item{
    display: flex;
    justify-content: space-between;
    cursor: pointer;
}
.item-title{
    color: #fff;
    margin-bottom: 1rem;
    max-width: 85px;
    white-space: nowrap;
    font-size: 12px;
    overflow: hidden;
    text-overflow: ellipsis;
}
.item-city{
    color: #fff;
    font-size: 12px;
}
.item-date{
    color: #fff;
    font-size: 12px;
}
.state-selector{
    display: flex;
    margin-top: 1rem;
}
.state-selector label{
    color: #fff;
}
.state-selector select{
    background: transparent;
    color: #fff;
    border: 2px solid rgb(172 255 195);
    padding: 4px;
    border-radius: 0.5rem;
}
.state-selector select option{
    color: #000;
}
.post-physical-tournament{
    font-size: 14px;
    color: #acffc3;
    font-weight: 600;
    padding: 0.5rem 1rem;
    margin-left: auto;
    margin-top: 1.5rem;
    margin-right: 0.5rem;
}
.casing{
    margin: 1rem 0;
}
.p-1{
    padding: 0.5rem 0.5rem;
}
.one-line{
    display: flex;
}
.pt-header{
    font-size: 24px;
    color: white;
}
.pt-modal-container{
    border: 1px grey solid;
    border-radius: 8px;
    height: 380px;
}
.pt-modal-close-button{
    display: flex;
    flex: 1;
    background-color: rgb(172 255 195);
    padding: 0.5rem 1rem;
    margin-left: auto;
    margin-top: 1.5rem;
    margin-right: 2rem
}
.modal-close-button {
    position: absolute;
    top: 10px;
    right: 20px;
    background-color: transparent;
    border: none;
    font-size: 14px;
    cursor: pointer;
  }
  .modal-header-img {
    position: absolute;
    top: 25px;
    left: 75%;
    transform: translateX(-50%);
    width: 80px;
    height: 80px;
    border-radius: 50%; /* Makes the image circular */
    border: 4px solid white; /* Optional: adds a border around the image */
  }
  .category-header{
    color: white;
  }
  .category-subtitle{
    color: grey;
    margin-left: 1rem;
    margin-bottom: 0.3rem;
  }
  .modal-body{
    max-height: 240px;
  }
  .createGameTitle{
    font-weight: 600;
  }
  .moreInfoCategories{
    display: flex;
    margin-top: 1rem;
    gap: 1rem;
    overflow: auto;
    position: relative;
    scrollbar-width: none;
  }
  .moreInfoCategories::after {
    content: ''; /* Add a pseudo-element for the fade effect */
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    width: 50px; /* Width of the fade */
    pointer-events: none; /* Ensure it doesn't block interactions */
    background: linear-gradient(to right, rgba(26, 44, 56, 0), #1a2c38); /* Fade to match the modal's background color */
  }

  .infoCategory{
    width: 166px;
    height: 66px;
    text-align: left;
    padding: 0.5rem 1rem;
    border: 1px solid gray;
    color: gray;
  }
  .infoCategory.selected{
    color: #fff;
    border: 2px solid #fff;
  }
  .infoCategory span{
    font-size: 13px;
    font-weight: 600;
  }
.infoContent{
    margin-top: 1rem;
}
  .categoryTop{
    color: white;
  font-size: 13px;
  font-weight: 600;
  width: 100%;
  display: flex;
  border-bottom: 1px solid white;
  padding: 4px 0;
  }
  .categorySubject{
    color: white;
    padding: 8px 0;
    font-size: 13px;
    display: flex;
  }
