* {
    user-select: none;
}
html{
    background-color: #1a181b;
}
body{
    padding: 0 !important;
    background-color: transparent !important;
}
.flex-fix{
    height: calc(100vh - 56px);
    position: relative;
}
.board-b72b1 {
    background-image: url(https://images.chesscomfiles.com/chess-themes/boards/icy_sea/200.png);
    background-size: cover;
    border-radius: 0.5rem;
    box-sizing: content-box;
  }

.tab-content-component{
        display: flex;
        flex: 1 1 0;
        flex-direction: column;
        min-height: 0;
}
.tournament-standings-tab-component{
    overflow: auto;
    justify-content: center;
    margin-right: auto;
    max-width: 288px;
    background: #242226;
    border-radius: 0.5rem;
}
.tournament-background{
    background-color: rgba(0, 0, 0, .2);
    border-radius: 0.5rem;
}
.tournament-standings-row-component{
    align-items: center;
    display: flex;
    height: 4rem;
    padding: 0 .8rem;
}
.tournament-standings-row-place {
    color: hsla(0,0%,100%,.72);
    flex: 0 0 auto;
    font-weight: 600;
    margin: 0 .5rem;
    text-align: center;
    width: 3rem;
}
.avatar-component {
    object-fit: cover;
}
.tournament-standings-row-avatar {
    flex: 0;
    margin-right: 1rem;
}
.user-tagline-component {
    align-items: center;
    display: flex;
    gap: .5rem;
    max-width: 100%;
    position: relative;
}
.tournament-standings-row-user {
    flex: 1 1 auto;
    min-width: 0;
}
.tournament-standings-row-score {
    color: var(--color-text-default);
    display: block;
    flex: 0 0 auto;
    font-size: 1.3rem;
    min-width: 2rem;
    text-align: right;
}
.tournament-standings-row-tiebreak {
    color: #8b8987;
    margin-left: 1rem;
    width: 3rem;
}
.user-tagline-component a.user-tagline-username {
    cursor: pointer;
}
.user-username-blue-with-dark-mode {
    color: hsla(0,0%,100%,.72);
}
.user-username-component {
    font-size: 14px;
    font-weight: 600;
    line-height: 1.3;
}
.user-tagline-rating {
    color: hsla(0,0%,100%,.72);
}
.column{
    flex-direction: column;
}
.countdown{
    color: hsla(0,0%,100%,.72);
    border-top-left-radius: 0.5rem;
    border-top-right-radius: 0.5rem;
    display: flex;
    justify-content: center;
    font-size: 14px;
    margin: 4px 0;
    gap: 4px;
}
.tournament-standings-row-score {
    color: hsla(0,0%,100%,.72);
    display: block;
    flex: 0 0 auto;
    font-size: 15px;
    min-width: 2rem;
    text-align: right;
}
.board-modal-component {
    background-color: #262421;
    border-radius: .6rem;
    box-shadow: 0 .3rem .63rem .07rem rgba(0, 0, 0, .4);
    flex: 0 0 auto;
    min-width: 28.6rem;
    position: relative;
}
.dark-mode .tournament-over-modal-content {
    background-color: #262421;
}
.dark-mode .tournament-over-modal-content .tournament-over-modal-header {
    background-color: #312e2b;
}
.tournament-over-modal-content .tournament-over-modal-header {
    margin-bottom: 0.8rem;
    padding-bottom: 2.75rem;
    padding-top: 2.75rem;
}
.tournament-over-modal-content .tournament-over-modal-header h3 {
    color: #ffffff;
    font-family: "Montserrat",sans-serif;
    font-size: 2.2rem;
    font-weight: 800;
    margin: 0;
    margin-bottom: .4rem;
    justify-content: center;
    display: flex;
}
.tournament-over-modal-content .tournament-over-modal-header .tournament-over-modal-subtitle {
    color: hsla(0,0%,100%,.72);
    font-weight: 600;
    margin: 0;
    justify-content: center;
    display: flex;
}
.tournament-over-standings-component {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    height: auto;
    margin: 0 auto;
    padding-bottom: 2.4rem;
    position: relative;
    width: calc(100% - 12rem);
}
.tournament-over-standing-component {
    align-items: center;
    display: flex;
    flex-direction: row;
    gap: 1rem;
    height: 6.4rem;
    width: 100%;
}
.tournament-over-standing-component.tournament-over-standing-first-place .tournament-over-standing-avatar-container {
    background-color: rgb(58, 188, 53);
}
.dark-mode .tournament-over-standing-component .tournament-over-standing-avatar-container {
    background: #4b4847;
}
.tournament-over-standing-component .tournament-over-standing-avatar-container {
    align-items: center;
    border-radius: .4rem;
    display: flex;
    flex-direction: column;
    height: 4rem;
    justify-content: center;
    width: 4rem;
}
.tournament-over-standing-component .tournament-over-standing-info-container {
    display: flex;
    flex-direction: column;
    margin: auto 0;
}
.tournament-over-standing-component .tournament-over-standing-info-container .tournament-over-standing-tagline {
    align-items: center;
    display: flex;
    flex-direction: row;
    max-width: 14rem;
    overflow: hidden;
    width: auto;
    color: hsla(0,0%,100%,.72);
}
.tournament-over-standing-component .tournament-over-standing-avatar-container .tournament-over-standing-avatar {
    border: .1rem solid rgba(0, 0, 0, .05);
    border-radius: .4rem;
}
.avatar-component {
    object-fit: cover;
}
.insufficient{
    color: hsla(0, 0%, 100%, .72);
    display: flex;
    justify-content: center;
    margin-bottom: 4rem;
}
.white{
    color: #ffffff;
}
.board-standing-container{
    width: calc(100% - 84px);
    height: calc(100% - 56px);
    top: 56px;
    position: relative;
}
.group-title{
    color: #a7a7a7;
    font-size: 12px;
    font-weight: 700;
  }
  .username-grey{
    color: #a7a7a7
  }
  .news-icon{
    margin-right: 12px;
  }
  @media (max-width: 1100px){
    .menu-group{
      justify-content: center;
      align-items: center;
    }
    .news-section{
      min-width: 48px;
    }
    .news-section .group-title .news-icon{
      margin-right: 0px;
    }
    .news-section .group-title .icon-title{
      display: none;
    }
    .articleInfo{
      display: none;
    }
  }
  .lobby-clock {
    width: 100px;
    max-height: 98px;
    color: #fff;
    background-color: #242226;
    border-radius: 0.5rem;
    padding: 1rem;
    padding-bottom: 1.5rem;
    left: calc(50% - 125px);
    position: absolute;
  
    /* Initial state (off-screen) */
    top: -150px;
    opacity: 0;
  
    /* Animation */
    animation: slideDown 0.5s ease-out forwards;
  }
  
  /* Slide-down animation */
  @keyframes slideDown {
    to {
      top: -70px; /* Final position */
      opacity: 1;
    }
  }
  .volume{
    display: flex;
    justify-content: center;
    gap: 0.5rem;
  }
  .lobby-countdown{
    display: flex;
    justify-content: center;
  }
  .cap{
    font-size: 1.5rem;
  }
  .cap.num{
    margin-top: 0.5rem;
  }
  .winner{
    border: 3px red solid;
  }