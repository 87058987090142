.home-header-logo {
    background-image: url('../../src/assets/images/newghchesslogo.png');
    height: 35px;
    width: 115px;
    margin-left: 1rem;
    margin-bottom: 0.5rem;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
}
.result-item{
    margin-top: 8px;
    display: flex;
    padding: 0 0.5rem;
    gap: 1rem;
}
.result-item-pic{
    height: 40px;
    border-radius: 0.5rem;
}
.follow-button-container{
    flex: 1;
    align-items: center;
    display: flex;
}
.follow-button{
    height: 30px;
    background-color: rgb(0, 149, 246);
    border-radius: 4px;
    margin-left: auto;
    padding: 0 1rem;
    color: #fff;
}
.following-button{
    height: 30px;
    background-color: rgb(239, 239, 239);
    border-radius: 4px;
    margin-left: auto;
    padding: 0 1rem;
    color: #000;
}
#typeaheadDropdown .result-item:last-child {
    margin-bottom: 8px;
}
.no-results{
    display: flex;
    justify-content: center;
    margin-top: 20px;
}
.user-profile{
    display: flex;
    margin-top: 4px;
}
.dollar-sign{
    align-items: center;
    display: flex;
    margin-left: 4px;
    margin-top: 2px;
}