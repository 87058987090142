.player-component{
    flex: 1;
    display: flex;
    flex-direction: row;
    gap: 1rem;
  }
  .player-component .player-avatar{
    display: flex;
  }
  .player-avatar-component img{
    object-fit: cover;
    border-radius:.5rem;
    height: 4rem;
    min-width: 3rem;
  }
  .player-component .player-tagline{
    margin: 0;
    overflow: visible;
  }
  .user-tagline-compact-row{
    display: flex;
    gap: 0.6rem;
    }
    .user-tagline-compact-username{
      color: rgb(139,152,169);  
    }
    .user-username-link{
      cursor: pointer;
  }
  .user-name-component{
    color: rgb(247, 249, 249);
    font-weight: 700;
    font-size: 1rem;
    font-family: Helvetica, Arial, sans-serif;
    line-height: 1.5rem;
  }
  .user-username-component{
    font-family: Helvetica, Arial, sans-serif;
    font-size: 0.8rem;
    font-weight: 700;
    line-height: 1.3rem;
  }
  .user-tagline-compact-name{
    white-space: nowrap;
  }
  .user-tagline-compact-username{
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .user-name-component {
    color: rgb(247, 249, 249);
    font-weight: 700;
    font-size: 1rem;
    font-family: Helvetica, Arial, sans-serif;
    line-height: 1.5rem;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    max-width: 7rem;
  }
  .clock-component{
    display: flex;
    justify-content: center;
  }
  .clock-component.clock-black {
    color: #fff;
    background-color: #262421;
  }
  .clock-component:not(.clock-player-turn) {
    opacity: .8;
  }
  .clock-component {
    background-clip: content-box;
    height: 2rem;
    line-height: 2rem;
    align-items: center;
    border-radius: 0.5rem;
    display: flex;
    flex-direction: row;
    max-width: 5.375rem;
    align-self: center;
  }
  .clock-arrow-arrow.clock-arrow-top {
    border-bottom-color: #262421;
    border-top: none;
    top: 0;
    transform: translateY(-100%);
  }
  .clock-arrow-arrow {
    border: 1.2rem solid #fff;
    border-left: 1.4rem solid transparent;
    border-right: 1.4rem solid transparent;
    display: block;
    height: 0;
    margin: auto;
    position: absolute;
    width: 3rem;
  }
  .clock-time-monospace {
    font-size: 1.4rem;
    font-weight: 700;
    letter-spacing: .2rem;
    margin: 0 1rem;
    white-space: nowrap;
  }
  .clock-component.clock-white {
    color: #262421;
    background-color: #fff;
  }
  .clock-arrow-arrow.clock-arrow-bottom {
    border-bottom: none;
    bottom: 0;
    transform: translateY(100%);
  }
  .follow-btn-container{
    display: flex;
  }
  .in-game-follow{
    background-color: rgb(58, 188, 53);
    border-radius: 0.4rem;
    color: #ffffff;
    display: inline-flex;
    position: relative;
    justify-content: center;
    vertical-align: middle;
    overflow: hidden;
    text-decoration: none;
    white-space: nowrap;
    user-select: none;
    height: 1.5rem;
    font-weight: 600;
    font-size: 14px;
    border: none;
    align-items: center;
    gap: 0.2rem;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif;
  }
  .in-game-follow svg{
    fill: #ffffff;
    animation-duration: 0.6s;
    animation-fill-mode: both;
    animation-timing-function: ease;
  }
  .follow:hover{
    background-color: rgb(52, 168, 48);
  }
  .follow-btn-container:hover .in-game-follow svg{
    transform: scale(1.1);
  }
  .board-layout-player{
    flex-shrink:0;
    margin:0;
  }
  .casing{
    border-radius: 0.5rem;
    padding: 1rem;
  }
  @media (min-width: 1100px) and (max-width: 1919px) {
    .board-standing-container {
        width: calc(100% - 244px) !important;
    }
  }
  @media(max-width: 960px){
    .lft-shell.visible{
      display: none;
    }
    .board-standing-container{
      width: 100% !important;
      left: 0;
      top: 0 !important;
    }
    .tab-content-component{
      flex-direction: row;
      width: 100%;
      margin-top: 2rem;
    }
    .tab-content-component .tournament-standings-tab-component{
      position: absolute;
        margin-top: 35rem;
    }
    #board-layout-main{
      justify-content: center;
      flex-direction: column;
    }
    .history-parent.in-game{
      padding-right: 2rem;
    }
    .history-parent .move-history{
      height: 2rem;
    }
    #board-layout-player-top{
      margin: auto;
    }
    #board-layout-player-bottom{
      margin: auto;
    }
    header.top-page{
      position: relative;
    }
  }
  .bck-error{
    background: #ed4163 !important;
  }
  .ig-profile{
    padding: 1rem;
  }
  .ig-profile.top{
    padding-bottom: 0;
  }
  .ig-profile.bottom{
    padding-top: 0;
  }