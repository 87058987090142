
.media-group{
    padding: 1rem;
  }
  .medium-title{
    font-size: 32px;
    border-bottom: 0.5px solid #2b2b2b;
    color: #ffffff;
  }
  .home-greeting{
    color: white;
    font-size: 24px;
  }
  .weekly-elo-box{
    color: white;
    border-radius: 0.5rem;
    padding: 1rem;
    margin-top: 1rem;
    background: #242226;
    border: 2px solid #242226;
  }
  .weekly-elo{
    color: white;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    gap: 1rem;
    border-radius: 0.5rem;
    background: #242226;
    border: 2px solid #242226;
  }
  .elo-group{
    font-size: 16px;
    display: flex;
    gap: 1rem;
  }
  .history-catalog{
    display: flex;
    gap: 3rem;
    width: auto;
    min-width: 600px;
    max-width: 600px;
    overflow-x: auto;
    white-space: nowrap;
    margin: 0 20px;
    margin-right: 0;
    height: 253px;
    align-items: center;
  }
  .person-history{
    margin-top: 1rem;
  }
  .history-info{
    margin-top: 0.5rem;
  }
  .person-history .username{
    color: white;
    font-size: 16px;
  }
  .person-mw{
    max-width: 90px;
  }
  .person-history .description{
    color: #a7a7a7;
    font-size: 12px;
    gap: 0.5rem;
    display: flex;
  }
  .fit-w{
  min-width: fit-content;
  }
  .p-1{
  padding: 1rem;
  }
  .history-arrow{
    display: flex;
    align-items: center;
  }
  .history-arrow .arrow{
    cursor: pointer;
  }
  .history-arrow .arrow {
    transition: transform 0.3s ease, fill 0.3s ease; 
  }
  .history-arrow .arrow:hover {
    fill: rgba(255, 255, 255, 0.3); 
    transform: scale(1.1); 
  }
  .dates{
    display: flex;
    justify-content: space-between;
    padding: 1rem;
    border-bottom: 0.5px solid #a7a7a7;
  }
  .dates .pair{
    min-width: 50px;
  }
  .not-today{
    color: #a7a7a7;
    cursor: pointer;
  }
  .not-today:hover {
    color: #b9b9b9; /* Change this to the desired hover color */
  }
  .today{
    color: #ffffff;
  }
  .pair .day{
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .pair .date{
    font-size: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .games.row {
    display: flex;
    flex-wrap: wrap; 
    justify-content: space-between;
  }
  
  .finished {
    flex: 0 1 50%;
    box-sizing: border-box; 
    margin-bottom: 10px;
    padding: 10px; 
  }
  .finished.one {
    border-right: 1px solid grey;
  }
  .games .finished{
    color: #a7a7a7;
  }
  .games .player{
    display: flex;
  }
  .country-flag{
    min-width: 20px;
  }
  .games .player .ranking{
    margin: 0 1rem;
    min-width: 10px;
    display: flex;
    align-items: center;
    font-size: 10px;
  }
  .games .finished .won{
    color: #ffffff;
  }
  .online-friend {
    background: linear-gradient(45deg, #ff6f61, #6a5acd); /* Cool gradient */
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); 
    color: white; 
    font-family: Arial, sans-serif;
  }
  .friend{
    display: flex;
    padding: 6px;
    padding-left: 12px;
    justify-content: space-between;
    align-items: center;
  }
  .skeleton-loader {
    display: flex;
    align-items: center;
    margin-bottom: 1rem;
    gap: 1rem;
    padding: 0 1rem;
    border-radius: 8px;
  }
  
  .skeleton-avatar {
    width: 20px;
    height: 20px;
    background: linear-gradient(90deg, #1e1e1e, #2a2a2a, #1e1e1e);
    border-radius: 4px;
    animation: shimmer 1.5s infinite linear;
  }
  
  .skeleton-text {
    flex: 1;
    height: 16px;
    background: linear-gradient(90deg, #1e1e1e, #2a2a2a, #1e1e1e);
    border-radius: 4px;
    animation: shimmer 1.5s infinite linear;
  }
  
  @keyframes shimmer {
    0% {
      background-position: -200px 0;
    }
    100% {
      background-position: 200px 0;
    }
  }
  
  .online-dot{
    width: 10px;
    height: 10px;
    border-radius: 9999px;
    background-color: green;
    position: relative;
    top: -15%;
    right: -80%;
  }
  @import url('https://fonts.googleapis.com/css2?family=Press+Start+2P&display=swap');
  
  .ticker-container {
    width: 100%;
    margin-top: 1rem;
    overflow: hidden;
    border: 2px solid grey;
    border-radius: 8px;
    background-color: transparent;
    color: white;
    padding: 10px 0;
    position: relative;
    display: flex;
    justify-content: center;
    font-family: 'Press Start 2P', cursive; 
  }
  
  .ticker {
    display: flex;
    flex-wrap: nowrap;
    animation: scroll-left 20s linear infinite;
    white-space: nowrap;
  }
  
  .ticker-item {
    margin-right: 50px;
    font-size: 12px; 
    letter-spacing: 2px; 
  }
  
  .winner-username {
    font-weight: bold;
    color: #ffffff;
  }
  
  .amount {
    color: rgb(172 255 195);
  }
  
  .winner-date {
    color: #ecf0f1;
  }
  
  @keyframes scroll-left {
    0% {
      transform: translateX(100%);
    }
    100% {
      transform: translateX(-100%);
    }
  }
  .sidenote-message{
    color: #a7a7a7;
    font-weight: 600;
    margin-top: 1rem;
    font-size: 12px;
    margin-left: 1rem;
    font-family: "Inter","Roobert","Helvetica Neue",Helvetica,Arial,sans-serif;
  }
  .game-scroll{
    display: flex;
    padding: 1rem;
    margin-left: auto;
  }
  .empty-gamelist{
    justify-content: center;
    color: #fff;
    margin: 1rem 0;
  }
  .p2{
    padding: 2rem;
  }
  .p3{
    padding: 3rem;
  }
  .win{
    color: rgb(172 255 195);
  }
  .of-list{
    gap: 1rem;
    display: flex;
    flex-direction: column;
  }
  .of-header{
    margin-top: 1rem;
  }
  .terms{
    display: flex;
    font-size: 12px;
  }
  #agreeToTerms{
    width: 14px;
    margin-right: 1rem;
  }
  .tos{
    margin: 0 0.2rem;
  }
  .priv-p{
    margin: 0 0.2rem;
  }
  .banner-spc{
    margin: 0.5rem 0;
  }
  .twenty-fourTime{
    color: #fff;
    display: flex;
    justify-content: space-between;
    max-width: 750px;
    font-size: 12px;
    margin-top: 0.5rem;
  }
  .timeNow{
    color: rgb(255, 255, 255);
    display: flex;
    font-size: 12px;
    margin: 0.5rem 0;
    max-width: 750px;
  }
  .chartControls{
    display: flex;
    gap: 5rem;
    color: #fff;
    font-size: 13px;
  }
  .timeType{
    margin-right: 0.5rem;
  }
  .length{
    margin-right: 0.5rem;
  }
  .timeControlResult{
    font-size: 20px;
  }
  .historyLengthResult{
    display: flex;
    font-size: 16px;
    color: rgb(172, 255, 195);
  }
  .balance-section{
    color: #fff;
  font-size: 14px;
  flex: 1;
  max-width: 375px;
  }
  .balance-section-header{
    justify-content: space-between;
  }
  .home-balance{
    font-size: 20px;
  }
  .overall-balance{
    min-height: 115px;
    padding: 0 0 20px 20px;
    border-bottom: 0.5px solid #2b2b2b;
  }
  .withdrawable-balance{
    min-height: 115px;
    padding: 20px 0 0 20px;
  }
  .home-balance-message{
    font-size: 11px;
    color: #888;
  }
  .home-withdrawButton{
    color: rgb(172 255 195);
    font-size: 13px;
  }
  .group-content{
    display: flex;
    margin-top: 1.5rem;
  }
  #search-box:focus {
    outline: none; /* Removes the outline */
    border: none; /* Removes the border */
    box-shadow: none; /* Removes any shadow (if applied) */
  }
  @media (max-width: 1420px) {
    .balance-section {
      display: none !important;
    }
  }
  @media (max-width: 1100px) {
    .home-nav-4 {
      padding: 6px 12px;
    }
  }
  @media (max-width: 1100px) {
    .home-nav-text-div {
      display: none;
    }
  }
  @media (max-width: 1100px) {
    .menu-group {
      padding: 1rem 0;;
    }
  }
  @media (max-width: 1100px) {
    icon-button {
      display: none;
    }
  }
  @media (max-width: 1100px) {
    .home-header-logo {
      background-image: url(../assets/images/shortlogo.png);
      background-position: 50%;
      background-repeat: no-repeat;
      background-size: contain;
      height: 35px;
      margin-bottom: .5rem;
      margin-left: 0.5rem;
      width: 50px;
    }
  }
  .no-friends-message{
    text-align: center;
    padding: 2rem;
    font-size: 14px;
    font-weight: 600;
  }