.casino-chip.one{
    background-color: rgb(58, 188, 53);
  }
  .casino-chip.five{
    background-color: rgb(255, 73, 104);
  }
  .casino-chip.ten{
    background-color: rgb(208 192 27);
  }
  .casino-chip.twenty{
    background-color: #fff;
  }
  .inner-chip.one{
    background: radial-gradient(circle, white, #86c954)
  }
  .inner-chip.five{
    background: radial-gradient(circle, white, rgb(255, 73, 104));
  }
  .inner-chip.ten{
    background: radial-gradient(circle, white, #5f913a);
  }
  .inner-chip.twenty{
    background: radial-gradient(circle, white, #2e3094);
  }
  .stripe.one{
    background-color: #343434;
  }
  .stripe.five{
    background-color: #ffffff;
  }
  .vertical.stripe.ten{
    background-color: #5f913a;
  }
  .horizontal.stripe.ten{
    background-color: #a76700;
  }
  .stripe.twenty{
    background-color: #2e3094;
  }
  .casino-chip {
    border-radius: 9999px;
    overflow: hidden;
    width: 6rem;
    height: 6rem;
    margin-bottom: 3rem;
    margin-right: 2rem;
    position: fixed;
    right: 0;
    bottom: 0;
    display: grid;
  }
  .stripe{
    z-index: 2;
    position: absolute;
  }
  .horizontal{
    width: 100%;
    height: 0.75rem;
    display: flex;
    align-self: center;
  }
  .vertical{
    width: 0.75rem;
    height: 100%;
    display: flex;
    justify-self: center;
  }
  .inner-chip{
    border-radius: 9999px;
    overflow: hidden;
    width: 4.5rem;
    height: 4.5rem;
    align-self: center;
    justify-self: center;
    z-index: 3;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif;
  }
  .gh-title{
    margin-top: 0.25rem;
    margin-bottom: 0.1rem;
  }
  .gh-title .gh{
    display: flex;
    justify-content: center;
    font-weight: 700;
  }
  .gh-title .gh-chess{
    display: flex;
    justify-content: center;
    font-size: 0.8rem;
  }
  .wager-pot{
    display: flex;
    justify-content: center;
    font-weight: 700;
    font-size: 0.875rem;
  }