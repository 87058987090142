.modal-overlay{
    width: 400px;
    justify-content: center;
    display: flex;
    border-radius: 0.5rem;
    flex-direction: column;
    background-color: #242226;
    position: absolute;
    top: 220px;
    left: 75px;
    z-index: 9999;
}
.standing-spacing{
    gap: 1rem;
    display: flex;
    flex-direction: column;
    margin: 1rem 0;
}
.mt-1{
    margin-top: 1rem;
}
.close-button {
    position: absolute;
    top: 0px;
    right: 15px;
    background: none;
    border: none;
    font-size: 24px;
    cursor: pointer;
    color: #333;
  }
.modal-header{
    background-color: rgb(101, 145, 113);
    border-top-right-radius: 0.5rem;
    border-top-left-radius: 0.5rem;
}
.to-header{
    font-size: 1.5rem;
    color: #fff;
}
.center{
    display: flex;
    justify-content: center;
}
.white{
    color: white;
}