.response-buttons{
    display: flex;
    justify-content: center;
    gap: 5rem;
}
button.accept{
    padding: 1rem;
   background-color: rgb(172 255 195);
   width: 48%;
  justify-content: center;
  display: flex;
}
button.accept .chessboard{
    width: 20px;
  height: 20px;
  background: linear-gradient(to bottom right, black 100%, transparent 50%) top left / 50% 50% no-repeat, linear-gradient(to top left, black 100%, transparent 50%) bottom right / 50% 50% no-repeat;
  border: 2px black solid;
  border-radius: 2px;
  box-sizing: border-box;
}
button.reject{
    padding: 1rem;
    background-color: #ed4163;
    width: 48%;
  justify-content: center;
  display: flex;
}
.response-buttons{
    margin-bottom: 1rem;
}