@media (max-width: 1100px){
    .news-section{
      min-width: 48px;
    }
    .news-section .group-title .news-icon{
      margin-right: 0px;
    }
    .news-section .group-title .icon-title{
      display: none;
    }
    .articleInfo{
      display: none !important;
    }
    .home-nav-text span{
        display: none;
    }
  }
  .color-tab.home{
    top: 90px;
  }
  .color-tab.tournament-tab{
    top: 127px;
  }
  @media (max-width: 1100px) {
    .news-section {
      display: none !important;
    }
  }