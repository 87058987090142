html{
    font-size: 0.875rem;
    line-height: 1.5rem;
    font-weight: 600;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', sans-serif;
}
body{
    background: #1a181b;
    margin: 0;
}
a{
    text-decoration: none;
}
.register.card.samri-vepx8a{
    background-color: transparent;
}
.left-wrapper.samri-kkwxsm{
    text-align: left;
}
.content.samri-1kvrppx {
    padding: 0 !important;
}
span.samri-1myjzud{
    text-align: left;
}
.label-left-wrapper.samri-1pryo3e {
    background-color: transparent !important;
}
.mgt-2{
    margin-top: 36px;
}
.mgb-1{
    margin-bottom: 12px;
}
.smallerlogo{
    background-image: url(C:\xampp\htdocs\ghsite\my-app\src\assets\images\smallerlogo.png);
    background-size: cover; /* or use 'contain' if you prefer */
    background-repeat: no-repeat;
    background-position: center;
    width: 75px;
    height: 45px;
    display: inline-block;
}
.button.size-xl.samri-1epmied {
    padding: 0.875rem 1.75rem !important;
}
.flex{
    display: flex;
}
.hidden {
    display: none;
}
@media (min-width: 1280px) {
    .xl\:flex-none {
        flex: none;
    }
}
.pl-2{
    padding-left: 0.5rem;
}

@media (min-width: 1280px) {
    .xl\:max-w-none {
        max-width: none;
    }
}
@media (min-width: 1280px) {
    .xl\:mt-24 {
        margin-top: 6rem;
    }
}
@media (min-width: 1280px) {
    .xl\:mr-0 {
        margin-right: 0px;
    }
}
@media (min-width: 1280px) {
    .xl\:ml-10 {
        margin-left: 2.5rem;
    }
}
@media (min-width: 1024px) {
    .lg\:max-w-2xl {
        max-width: 42rem;
    }
}
@media (min-width: 1024px) {
    .lg\:flex {
        display: flex;
    }
}
@media (min-width: 1024px) {
    .lg\:mt-16 {
        margin-top: 4rem;
    }
}
@media (min-width: 1024px) {
    .lg\:mx-auto {
        margin-left: auto;
        margin-right: auto;
    }
}
@media (min-width: 1024px) {
    .lg\:max-w-none {
        max-width: none;
    }
}

@media (min-width: 640px) {
    .sm\:max-w-5xl {
        max-width: 64rem;
    }
}
.flex-none {
    flex: none;
}
.max-w-3xl {
    max-width: 48rem;
}
.my-auto {
    margin-top: auto;
    margin-bottom: auto;
}
.bg-white\/5 {
    background-color: rgb(255 255 255 / 0.05);
}

.rounded-md {
    border-radius: 0.375rem;
}
.w-\[76rem\] {
    width: 76rem;
}
img, video {
    max-width: 100%;
    height: auto;
}
img, svg, video, canvas, audio, iframe, embed, object {
    display: block;
}
.min-h-full {
    min-height: 100vh;
}
.ring-1{
    box-shadow: 0 25px 50px -12px rgb(0 0 0 / 0.25);
}
.ring-white\/10 {
    border: 1px rgba(215, 215, 215, 0.1) solid;
}
.top-0 {
    top: 0px;
}

.inset-x-0 {
    left: 0px;
    right: 0px;
}
.sticky {
    position: sticky;
}
.border-b-gray-400\/10 {
    border-bottom-color: rgb(156 163 175 / 0.1);
}

.border-b {
    border-bottom-width: 1px;
}
.flex-row {
    flex-direction: row;
}
.flex {
    display: flex;
}
.bg-\[\#181523\] {
    background-color: rgb(18 21 20);
}
.p-2 {
    padding: 0.5rem;
}

.gap-x-2 {
    -moz-column-gap: 0.5rem;
    column-gap: 0.5rem;
}
.flex-row {
    flex-direction: row;
}
.w-4 {
    width: 1rem;
}

.h-4 {
    height: 1rem;
}
.pr-2 {
    padding-right: 0.5rem;
}

.flex-row {
    flex-direction: row;
}
.flex {
    display: flex;
}
.ml-auto {
    margin-left: auto;
}
.p-4 {
    padding: 1rem;
}

.h-full {
    height: 100%;
}
.w-7 {
    width: 1.7rem;
}

.h-7 {
    height: 1.7rem;
}
.text-white {
    color: rgb(255 255 255 / 1);
}

.font-semibold {
    font-weight: 600;
}
.text-\[10px\] {
    font-size: 10px;
}
.p-2 {
    padding: 0.5rem;
}
.border-gray-400\/50 {
    border-color: rgb(156 163 175 / 0.5);
}
.border {
    border-width: 1px;
    border-style: solid;
}
.rounded-sm {
    border-radius: 0.125rem;
}
.gap-x-1 {
    column-gap: 0.25rem;
}
.discord-path:hover{
    background-color: rgba(142, 142, 142, 0.1);
    border-bottom: 3px rgb(101,145,113) solid;
}
h1.weight-semibold.samri-1myjzud {
    font-weight: 700;
}
h1.text-size-md.samri-1myjzud {
    font-size: 1.5rem;
}
.footer.samri-kkwxsm {
    margin-bottom: 1rem;
}
h1.samri-1myjzud {
    margin-bottom: 0;
}
.loader {
    border: 16px solid #f3f3f3; /* Light grey */
    border-top: 16px solid #3498db; /* Blue */
    border-radius: 50%;
    width: 120px;
    height: 120px;
    animation: spin 2s linear infinite;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
.fg-0{
    flex-grow: 1;
}
.p-0{
    padding: 0;
}
@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}
.w-1rem{
    width: 1rem;
}
.ml-1rem{
    margin-left: 1rem;
}
.f-875{
    font-size: 0.875rem;
}
.fg-n{
    flex-grow: 0;
}
.cash-prize-input{
   background: transparent;
   margin-left: 0.5rem;
}
.cash-prize-input-div{
    flex-direction: row;
    max-width: 90px;
}