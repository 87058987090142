#placeholder-26282ir{
    justify-content: center;
    display: flex;
}
#placeholder-26282bye{
    justify-content: center;
    display: flex;
    position: absolute;
    top: 0;
    left: -120px;
    right: -120px;
    bottom: 0;
}
#placeholder-26282ga{
    position: absolute;
    display: flex;
    justify-content: center;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
}
.board-modal-container-container {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    min-height: 100%;
    position: absolute;
    top: 0;
    z-index: 1000;
    opacity: 0;
    transform: scale(0.9);
    transition: opacity 0.3s ease, transform 0.3s ease;
}
.jc-center{
    justify-content: center;
}
.board-modal-container-container.show {
    opacity: 1;
    transform: scale(1);
}
button{
    cursor: pointer;
}
.board-modal-component {
    box-shadow: 0 0.3rem 0.63rem 0.07rem rgba(0,0,0,.4);
    flex: 0 0 auto;
    position: relative;
}
.game-over-modal-container {
    background: none;
    border-radius: 1rem;
    max-width: 18.75rem;
    overflow: hidden;
}
.game-over-modal-content {
    background-color: #262421;
    display: flex;
    flex-direction: column;
    padding-bottom: 1.6rem;
    position: relative;
    row-gap: 1.6rem;
    text-align: center;
}
.board-modal-header-component {
    background-color: #666463;
    border-radius: .6rem .6rem 0 0;
    display: flex;
    flex-direction: column;
    height: 7rem;
    justify-content: center;
    text-align: center;
}
.game-over-header-component {
    background-color:   rgb(101, 145, 113);
    color: #ffffff;
    display: flex;
    flex-direction: row;
    height: auto;
    padding-top: 2rem;
    padding-bottom: 1rem;
    width: 100%;
}
.game-over-header-component .game-over-header-icon {
    height: 4.4rem;
    margin: 1rem 0;
    justify-self: center;
    width: 4.4rem;
    display: flex;
}
.svg-color-icon-icon {
    background-repeat: no-repeat;
    background-size: contain;
}
.game-over-header-component .game-over-header-header {
    display: grid;
    flex-direction: column;
    height: auto;
    margin: auto 0;
}
.header-title-component.header-title-has-trophy-icon {
    width: 15.6rem;
}
.header-title-component {
    flex: 0 0 auto;
    font-family: "Montserrat",sans-serif;
    font-size: 1.6rem;
    font-weight: 700;
    margin: 0 auto;
    text-align: center;
}
.header-subtitle-component {
    flex: 0 0 auto;
    font-size: 1.1rem;
    font-weight: 500;
    line-height: 2rem;
    max-width: 23.6rem;
    opacity: .7;
    position: relative;
}
.header-subtitle-first-line, .header-subtitle-overflow {
    margin: 0 auto;
    text-align: center;
    width: 100%;
    color: #000;
}
.board-modal-header-component .board-modal-header-close {
    background-color: transparent;
    border: none;
    font-size: 2rem;
    height: 3rem;
    margin: 0;
    padding: 0;
    position: absolute;
    right: 0;
    top: 0;
    width: 3rem;
    color: #ffffff;
}
.game-over-header-component .game-over-header-close {
    margin: .2rem .2rem 0 0;
}
.dark-mode .game-over-header-component .game-over-header-close {
    color: hsla(0,0%,100%,.85);
}
a{
    color: inherit;
    text-decoration: none;
}
.game-over-buttons-component {
    align-content: center;
    display: flex;
    flex-direction: row;
    gap: .8rem;
    margin: 0 auto;
    padding: 1rem;
}
.ui_v5-button-component {
    align-items: center;
    background-color: hsla(0,0%,100%,.08);
    border: 0;
    border-radius: .5rem;
    box-shadow: inset 0 calc(.1rem * -1) 0 0 rgba(0, 0, 0, .2);
    color: hsla(0,0%,100%,.72);
    display: inline-flex;
    font-size: 1rem;
    font-weight: 600;
    justify-content: center;
    max-width: 100%;
    overflow: hidden;
    padding: .5rem 2rem;
    position: relative;
    text-align: center;
    text-shadow: 0 .1rem 0 rgba(0, 0, 0, .2);
    transition-duration: .1s;
    transition-property: box-shadow, background-color, color;
    transition-timing-function: ease-out;
}
.ui_v5-button-component:not(.ui_v5-button-full) {
    height: 3rem;
    white-space: nowrap;
}
.game-over-buttons-component .game-over-buttons-button {
    flex: 1 1 auto;
    width: 100%;
}
.ui_v5-button-component:hover{
    color: #ffffff;
    background-color: hsla(0, 0%, 100%, .16);
}
.game-over-player-component{
    display: flex;
    flex-direction: row;
    justify-content: center;
}
.game-over-player-component .game-over-player-player {
    align-items: center;
    display: flex;
    flex: 0 0 auto;
    flex-direction: column;
    justify-content: center;
    position: relative;
    min-width: 80px;
}
.game-over-player-component .game-over-player-player .game-over-player-username {
    color: rgb(247, 249, 249);
    font-weight: 700;
    font-size: 0.8rem;
    font-family: Helvetica, Arial, sans-serif;
    margin-top: .4rem;
    text-align: center;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 56px;
    white-space: nowrap;
}
.game-over-player-icon{
    margin: 0 1rem;
}
.game-over-player-middle{
    padding-bottom: 1.5rem;
    justify-content: center;
    align-items: center;
    display: flex;
}
.game-over-player-middle svg{
    height: 2rem;
    width: 2rem;
}
.tournament-over-buttons-component {
    align-content: center;
    display: flex;
    flex-direction: row;
    gap: .8rem;
    margin: 0 auto;
    padding: 1rem;
    padding-top: 0;
}
.point-bye-header-component{
    display: flex;
    background: rgb(101, 145, 113);
    color: #fff;
    border-top-right-radius: 0.5rem;
    border-top-left-radius: 0.5rem;
    padding: 1rem;
    justify-content: space-around;
}
.font-md{
    font-size: 20px;
}
.content-container-container{
    background: #fff;
    border-bottom-left-radius: 0.5rem;
    border-bottom-right-radius: 0.5rem;
    padding: 1rem;
}
.point-bye-container{
    max-width: 371px;
    font-family: Zen Antique system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}